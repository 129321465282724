<template>
  <div>
    <div class="row q-pa-xs border-bottom">
      <img
        style="height: 54px; width: 70px; object-fit: contain;"
        :src="data.image || fallbackImage"
        :alt="data.name"
        @error="onImageLoadFailure"
      >

      <div class="text-subtitle1 text-weight-bold q-px-sm">
        {{ name }}
      </div>
    </div>

    <div class="border-bottom q-pa-xs text-caption text-weight-bold">
      <div v-if="data._embedded.productOffer" class="row q-pb-xs">
        <div class="q-mr-sm q-px-xs" style="min-width: 80px;">
          {{ $t('SKU') }}
        </div>

        <div>
          <q-badge
            color="info"
            :label="data._embedded.productOffer.sku || ''"
            class="q-py-xs"
          />
        </div>
      </div>

      <div class="row q-pb-xs">
        <div
          class="q-mr-sm q-px-xs"
          style="min-width: 80px;"
        >
          {{ $t('Barcode')}}
        </div>

        <div>
          <q-badge
            color="warning"
            :label="data.sku || '--'"
            class="q-py-xs"
          />
        </div>
      </div>

      <div v-if="data._embedded.productOffer" class="row">
        <div class="q-mr-sm q-px-xs" style="min-width: 80px;">
          {{ $t('Article') }}
        </div>

        <div>
          <q-badge
            color="positive"
            :label="data._embedded.productOffer.article || '--'"
            class="q-py-xs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemSection',
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    name () {
      return this.data.name || `${this.$t('Id')}: ${this.data.id}`
    }
  },
  methods: {
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    }
  }
}
</script>
